import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import CriticalAlerts from '../alerts/CriticalAlerts';
import './index.css';
import TeamPerformance from '../alerts/TeamPerformance';
import BotAlerts from '../alerts/BotAlerts';

const StyledCard = styled(Card)`
  border-radius: 8px;
  background: var(--Dark-Dark-11-White, #FFF);
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 3px 5px 0px rgba(9, 30, 66, 0.20);
  padding-top: 8px;
  min-height: calc(100vh - 200px);
`;

const StyledTab = styled(Tab)`
  text-transform: capitalize;
  color: var(--Dark-Dark-03, #546077);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  &.Mui-selected {
    color: var(--Dark-Dark-01, #0E1E40);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }
`;

function CustomTabPanel(props) {
  const {
    children, value, index, p = 4,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  p: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
};

function TabularDashboard() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <StyledCard>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="settings tab"
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: 'var(--Blue-Blue-01, #213D69)',
            },
          }}
        >
          <StyledTab label="Critical Alerts" />
          <StyledTab label="Team Performance" />
          <StyledTab label="HR Bot Alerts" />
          <StyledTab label="IT Bot Alerts" />
          <StyledTab label="Infosec Bot Alerts" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} p={0}>
        <CriticalAlerts />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} p={0}>
        <TeamPerformance />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2} p={0}>
        <BotAlerts category="HR" />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <BotAlerts category="IT" />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <BotAlerts category="Infosec" />
      </CustomTabPanel>
    </StyledCard>
  );
}

export default TabularDashboard;
