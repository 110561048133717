import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { TeamAvatarIcon, UserIcon } from '../../components/Icon';

const MenuWrapper = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 296px;
  width: 100%;
  border-right: 1px solid #E0E1E6;
  border-bottom: 1px solid #E0E1E6;
  border-left: 1px solid #E0E1E6;
  background: #FFF;
  box-shadow: 0px 10px 44px 0px rgba(9, 30, 66, 0.09);
`;

const TeamsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  border-top: 1px solid #E0E1E6;
`;

const TeamsTitle = styled.button`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  color: #0E1E40;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 12px;
`;

const ItemsContainer = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: column;
  gap: 12px;
`;

const Item = styled.li`
  display: flex;
  padding: 6px 16px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &.active {
    background: #ECECF0;
  }
`;

const ItemDetail = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ItemTitle = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`;

const ItemName = styled.span`
  color: #0E1E40;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`;

const ItemInfo = styled.span`
  color: #293855;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

const ItemProgress = styled.span`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 3px solid #D8E6FC;
  align-items: center;
  justify-content: center;
  color: #4469A1;
  font-size: 12px;
  font-weight: 600;
`;

function Menu({
  teams,
  members,
  selectedMember,
  setSelectedMember,
  selectedTeam,
  setSelectedTeam,
}) {
  const [showTeams, setShowTeams] = useState(true);
  const [showMembers, setShowMembers] = useState(true);

  return (
    <MenuWrapper>
      <TeamsWrapper>
        <TeamsTitle onClick={() => setShowTeams(!showTeams)}>
          <span>{`TEAMS (${teams.length})`}</span>
          <i className="fa fa-caret-down" />
        </TeamsTitle>
        {showTeams ? (
          <ItemsContainer>
            {teams.map((team, key) => {
              const averageEngagementScore = team.engagementScore;
              return (
                <Item
                  key={key}
                  onClick={() => setSelectedTeam(team)}
                  className={`${team === selectedTeam ? 'active' : ''}`}
                >
                  <ItemDetail>
                    <TeamAvatarIcon width="32" height="32" color="#9E62BA" />
                    <ItemTitle>
                      <ItemName>{team.name}</ItemName>
                      <ItemInfo>{`${team.teamMates.length} members`}</ItemInfo>
                    </ItemTitle>
                  </ItemDetail>
                  <ItemProgress>
                    {`${averageEngagementScore}%`}
                  </ItemProgress>
                </Item>
              );
            })}
          </ItemsContainer>
        ) : null}
      </TeamsWrapper>
      <TeamsWrapper>
        <TeamsTitle onClick={() => setShowMembers(!showMembers)}>
          <span>{`MEMBERS (${members.length})`}</span>
          <i className="fa fa-caret-down" />
        </TeamsTitle>
        {showMembers ? (
          <ItemsContainer>
            {members.map((member, key) => (
              <Item
                key={key}
                onClick={() => { setSelectedMember(member.memberId); }}
                className={`${member.memberId === selectedMember ? 'active' : ''}`}
              >
                <ItemDetail>
                  <UserIcon width="32" height="32" color="#5A81BC" />
                  <ItemTitle>
                    <ItemName>{member?.member?.user.fullName}</ItemName>
                    <ItemInfo>{member?.role}</ItemInfo>
                  </ItemTitle>
                </ItemDetail>
                <ItemProgress>
                  {`${member?.member?.engagementScore || 0}%`}
                </ItemProgress>
              </Item>
            ))}
          </ItemsContainer>
        ) : null}
      </TeamsWrapper>
    </MenuWrapper>
  );
}

Menu.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.shape({
  })).isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({
  })).isRequired,
  setSelectedMember: PropTypes.func.isRequired,
  setSelectedTeam: PropTypes.func.isRequired,
  selectedMember: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedTeam: PropTypes.object.isRequired,
};

export default Menu;
