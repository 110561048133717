import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { Navbar, Sidebar } from '../../components';
import './index.css';
import TabularDashboard from '../userDashboard/TabularDashboard';

function Alerts() {
  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="screen-wrapper">
        <div className="screen-header">
          <div className="screen-header-container d-flex justify-content-between position-relative">
            <div className="d-flex align-c width-full justify-content-sm-between mb-2">
              <h3
                className="header-manage-member"
                style={{ fontWeight: 600 }}
                data-testid="title"
              >
                Team Engagement
              </h3>
              <Link to="/dashboard">
                <Button
                  variant="outlined"
                  sx={{
                    width: 114,
                    height: 36,
                    fontSize: 14,
                    padding: '8px',
                    textTransform: 'capitalize',
                    borderColor: '#E0E1E6',
                    color: '#213D69',
                  }}
                >
                  Shoot Insights
                </Button>
              </Link>
            </div>
          </div>
          <TabularDashboard />
        </div>
      </div>
    </>
  );
}

export default Alerts;
