import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  Button, Menu,
} from '@mui/material';
import { UnfoldMore } from '@mui/icons-material/';
import CustomTable from '../../components/table/CustomTable';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import MemberNameEmailColumn from '../../components/table/MemberNameEmailColumn';
import { formatDate } from '../../utils/time';

import MultiSelect from '../../components/muiCustomized/MultiSelect';
import ViewIssueModal from './ViewIssueModal';

const TABLE_HEADERS = [
  {
    label: 'Employee Name',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'name',
    colSpan: 1,
  },
  {
    label: 'Alert Type',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'alertType',
    colSpan: 0.5,
  },
  {
    label: 'Issue Description',
    colSpan: 1.5,
  },
  {
    label: 'Status',
    colSpan: 0.5,
  },
  {
    label: 'Date',
    colSpan: 0.5,
  },
  {
    label: 'Action',
    colSpan: 0.5,
  },
];

const CustomTableStyles = {
  container: {
    marginTop: 1,
  },
  header: {
    backgroundColor: 'var(--dark-10)',
    fontSize: '14px',
    '.MuiTableSortLabel-iconDirectionAsc': {
      transform: 'rotate(0deg) !important',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: 'var(--dark-10) !important',
    },
  },
  cell: {
    button: {
      textWrap: 'nowrap',
      color: 'var(--dark-2)',
      borderColor: 'var(--dark-4) !important',
      '&:disabled': {
        color: 'var(--dark-5)',
        borderColor: 'var(--dark-7) !important',
        cursor: 'not-allowed',
        pointerEvents: 'all !important',
      },
    },
  },
};

const AlertTypes = [
  { id: 0, name: 'All' },
  { id: 1, name: 'HR' },
  { id: 2, name: 'IT' },
  { id: 3, name: 'Infosec' },
  { id: 4, name: 'Other' },
];

const Status = [
  { id: 0, name: 'All' },
  { id: 1, name: 'Acknowledged' },
  { id: 2, name: 'Pending' },
  { id: 3, name: 'Resolved' },
  { id: 4, name: 'Error' },
];

function CriticalAlerts() {
  const { search } = useLocation();
  const { activeWorkspace } = useContext(UserContext);
  const query = new URLSearchParams(search);
  const page = query.get('page');
  const history = useHistory();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [alerts, setAlerts] = useState([]);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page, 10) : 1);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([{ id: 0, name: 'All' }]);
  const [selectedAlertTypes, setSelectedAlertTypes] = useState([{ id: 0, name: 'All' }]);
  const [selectedStatus, setSelectedStatus] = useState([{ id: 0, name: 'All' }]);
  // const [selectedManagers, setSelectedManagers] = useState([{id: 0, name: 'All'}]);
  // const [date, setDate] = useState([new Date(moment(today).subtract(6, 'days')), today]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onClickView = (alert) => {
    setSelectedAlert(alert);
    setIsOpenModal(true);
  };

  const handleClose = () => {
    setIsOpenModal(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}`);
  };

  const getAlerts = async (params) => {
    setIsLoading(true);
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getAlerts(activeWorkspace.id),
      params: {
        priority: 'critical',
        pageSize: 10,
        ...params,
      },
    });
    if (response.success) {
      const mappedAlerts = response.data?.alerts.map((alert) => ({
        user: <MemberNameEmailColumn
          firstName={alert?.member?.user?.firstName}
          lastName={alert?.member?.user?.lastName}
          email={alert?.member?.user?.email}
          component="div"
        />,
        alertType: `${alert?.alertType?.category} Bot`,
        issueDescription: alert?.alertType?.triggerCondition,
        status: <span className="alert-badge" data-status={alert?.status}>{ alert?.status }</span>,
        date: formatDate(alert.createdAt),
        actions: (
          <Button
            type="button"
            className="inactive-status"
            onClick={() => onClickView(alert)}
            variant="outlined"
          >
            View Issue
          </Button>
        ),
      }));
      setAlerts(mappedAlerts);
      setIsLoading(false);
      setTotalPages(response.data?.totalPages ?? 1);
    } else {
      // handle API errors
    }
  };

  const getAllMembers = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.members(activeWorkspace.id),
    });
    if (response.success) {
      const mappedmembers = response.data?.members.map((member) => ({
        id: member.id,
        name: member.user?.fullName,
      }));
      setMembers([{ id: 0, name: 'All' }, ...mappedmembers]);
    } else {
      // handle API errors
    }
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterMemberChange = (newValue) => {
    setSelectedMembers(newValue);
  };

  const handleFilterAlertTypeChange = (newValue) => {
    setSelectedAlertTypes(newValue);
  };

  const handleFilterStatusChange = (newValue) => {
    setSelectedStatus(newValue);
  };

  const handleReset = () => {
    setSelectedMembers([{ id: 0, name: 'All' }]);
    setSelectedAlertTypes([{ id: 0, name: 'All' }]);
    setSelectedStatus([{ id: 0, name: 'All' }]);
  };

  const handleSearch = () => {
    const memberIds = selectedMembers.map((item) => item.id);
    const status = selectedStatus.map((item) => item.name);
    const alertType = selectedAlertTypes.map((item) => item.name);
    const params = {
      memberIds: memberIds[0] === 0 ? null : memberIds,
      status: status[0] === 'All' ? null : status,
      alertType: alertType[0] === 'All' ? null : alertType,
      page: currentPage,
    };
    getAlerts(params);
  };

  const handleMarkAsAcknowledged = async () => {
    setIsUpdating(true);
    const response = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.acknowledgedByManager(selectedAlert?.id),
    });
    setIsUpdating(false);
    if (response.success) {
      setIsOpenModal(false);
      getAlerts({ page: currentPage });
    } else {
      // handle API errors
    }
  };

  useEffect(() => {
    getAlerts({ page: currentPage });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    getAllMembers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="py-2 px-3 d-flex justify-content-between align-items-center">
        <div>
          <h4 className="size-s text-600 lh-base fc-dark mb-1">
            Critical Alerts Window
          </h4>
          <p className="size-xs text-400 lh-sm fc-dark">
            Resolve any critical issues that require attention to get the member engaged
          </p>
        </div>
        <button className="filter-btn" type="button" onClick={handleFilterClick}>Filter & Search</button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiMenu-list': {
              padding: 0,
            },
          }}
        >
          <div className="filter-panel p-3">
            <div className="filter-label mb-1">Employee Name</div>
            <MultiSelect options={members} value={selectedMembers} handleChange={handleFilterMemberChange} />
            <div className="filter-label mb-1 mt-2">Alert Type</div>
            <MultiSelect
              options={AlertTypes}
              value={selectedAlertTypes}
              handleChange={handleFilterAlertTypeChange}
            />
            <div className="filter-label mb-1 mt-2">Status</div>
            <MultiSelect options={Status} value={selectedStatus} handleChange={handleFilterStatusChange} />
            {/* <div className="filter-label my-1">Manager Name</div>
            <MultiSelect options={members} value={selectedMembers} /> */}
            {/* <div className="filter-label my-1">Date</div>
            <MultiSelect options={members} value={selectedMembers} /> */}
            <div className="d-flex justify-content-end gap-3 mt-2">
              <button
                type="button"
                className="filter-reset-btn"
                onClick={() => handleReset()}
              >
                Reset
              </button>
              <button type="button" className="filter-search-btn" onClick={() => handleSearch()}>Search</button>
            </div>
          </div>
        </Menu>
      </div>
      <div>
        <CustomTable
          headers={TABLE_HEADERS}
          items={alerts}
          loading={isLoading}
          pages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          styles={CustomTableStyles}
        />
      </div>
      <ViewIssueModal
        selectedAlert={selectedAlert}
        isOpen={isOpenModal}
        handleClose={handleClose}
        handleMarkAsAcknowledged={handleMarkAsAcknowledged}
        isUpdating={isUpdating}
      />
    </>
  );
}

export default CriticalAlerts;
