import React from 'react';
import './index.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Navbar, Sidebar } from '../../components';
import CardDashboard from './CardDashboard';
import MaterialUISwitch from '../../components/MaterialUISwitch';
import TabularDashboard from './TabularDashboard';

function UserDashboard() {
  const [isChecked, setIsChecked] = React.useState(false);

  const handleSwitchChange = (event) => {
    setIsChecked(event.target.checked);
    // localStorage.setItem('tabularDashboard', event.target.checked);
  };

  // useEffect(() => {
  //   const tabularDashboard = localStorage.getItem('tabularDashboard');
  //   if (tabularDashboard === 'true') {
  //     setIsChecked(true);
  //   }
  // }, []);

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="screen-wrapper">
        <div className="screen-header insights">
          <div className="d-flex align-c width-full justify-content-sm-between mb-4">
            <h3 className="header-manage-member">Shoot Insights</h3>
            <FormGroup>
              <FormControlLabel
                control={(
                  <MaterialUISwitch
                    sx={{ m: 1 }}
                    checked={isChecked}
                    onChange={handleSwitchChange}
                  />
              )}
                label=""
              />
            </FormGroup>
          </div>
          {isChecked ? <TabularDashboard /> : <CardDashboard />}
        </div>
      </div>
    </>
  );
}

export default UserDashboard;
