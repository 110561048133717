import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import BotAlertCard from './BotAlertCard';
import ActivityStatus from './ActivityStatus';
import DateRangePicker from '../../components/dateRangePicker';
import Menu from './Menu';
import './index.css';
import OverallScoreCard from './OverallScoreCard';
import DailyQualityCard from './DailyQualityCard';
import CriticalAlertsCard from './CriticalAlertsCard';
import MajorShiftsCard from './MajorShiftsCard';
import EngagementCard from './EngagementCard';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { countWeekdaysInRange, currentMonthWeekRange, getDatesInRange } from '../../utils/time';
import MemberSelect from './MemberSelect';
import TeamSelect from './TeamSelect';

function CardDashboard() {
  const [hrBotAlerts, setHrBotAlerts] = useState([]);
  const [itBotAlerts, setItBotAlerts] = useState([]);
  const [infosecBotAlerts, setInfosecBotAlerts] = useState([]);
  const [alertsByMember, setAlertsByMember] = useState({});
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [activeSeconds, setActiveSeconds] = useState(0);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState({});
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [menuMembers, setMenuMembers] = useState([]);
  const [workDays, setWorkDays] = useState(5);
  const [dailyQualityHours, setDailyQualityHours] = useState(0);
  const [dailyTotalHours, setDailyTotalHours] = useState(0);
  const [memberAlerts, setMemberAlerts] = useState([]);
  const [date, setDate] = useState(currentMonthWeekRange());
  const { activeWorkspace } = useContext(UserContext);
  const [selectedMemberId, setSelectedMemberId] = useState(0);
  const [selectedTeamId, setSelectedTeamId] = useState(0);

  const getTopMembersByAlerts = (alertsByMember) => Object.entries(alertsByMember)
    .map(([memberId, alerts]) => ({
      memberId,
      userFullName: alerts[0].userFullName, // Assuming all alerts for a member have the same userFullName
      alertsCount: alerts.length,
    }))
    .sort((a, b) => b.alertsCount - a.alertsCount) // Sort by alert count in descending order
    .slice(0, 5);

  const getMembersData = async () => {
    const [startDate, endDate] = date;
    const dates = getDatesInRange(startDate, endDate);
    const startOfDay = moment(dates[0]).utc().startOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const endOfDay = moment(dates.at(-1)).utc().endOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const workPeriod = countWeekdaysInRange(startOfDay, endOfDay);
    setWorkDays(workPeriod);

    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.dashboardMembers(activeWorkspace.id),
      params: {
        startOfDay,
        endOfDay,
      },
    });

    const { teams, reportingManagerMembers } = response.data;

    const mergedTeammates = [];
    const userIds = new Set();
    const processedTeammates = new Map();

    const updatedTeams = teams.map((team) => {
      let teamTotalSecondsElapsed = 0;
      let teamTotalActiveSeconds = 0;

      const updatedTeamMates = team.teamMates.map((teammate) => {
        const { userId, dailyActivity } = teammate.member;

        if (processedTeammates.has(userId)) {
          const existingTeammate = processedTeammates.get(userId);
          teamTotalSecondsElapsed += existingTeammate.member.totalSecondsElapsed;
          teamTotalActiveSeconds += existingTeammate.member.totalActiveSeconds;
          return existingTeammate;
        }

        let totalSecondsElapsed = 0;
        let totalActiveSeconds = 0;

        // eslint-disable-next-line no-restricted-syntax
        for (const activity of dailyActivity) {
          if (activity.totalSeconds) {
            totalSecondsElapsed += activity.totalSeconds;
            totalActiveSeconds += activity.activeSeconds;
          }
        }

        const engagementScore = totalSecondsElapsed > 0
          ? Math.round((totalActiveSeconds * 100) / totalSecondsElapsed)
          : 0;

        // Update team-level totals
        teamTotalSecondsElapsed += totalSecondsElapsed;
        teamTotalActiveSeconds += totalActiveSeconds;

        // Create and cache the updated teammate
        const updatedTeammate = {
          ...teammate,
          member: {
            ...teammate.member,
            totalSecondsElapsed,
            totalActiveSeconds,
            engagementScore,
          },
        };

        processedTeammates.set(userId, updatedTeammate);
        userIds.add(userId);
        mergedTeammates.push(updatedTeammate);

        return updatedTeammate;
      });

      const teamEngagementScore = teamTotalSecondsElapsed > 0
        ? Math.round((teamTotalActiveSeconds * 100) / teamTotalSecondsElapsed)
        : 0;

      return {
        ...team,
        totalSecondsElapsed: teamTotalSecondsElapsed,
        totalActiveSeconds: teamTotalActiveSeconds,
        engagementScore: teamEngagementScore,
        teamMates: updatedTeamMates,
      };
    });

    reportingManagerMembers.forEach((member) => {
      const { userId, dailyActivity } = member;

      if (!userIds.has(userId)) {
        let totalSecondsElapsed = 0;
        let totalActiveSeconds = 0;

        dailyActivity.forEach((activity) => {
          if (activity.totalSeconds) {
            totalSecondsElapsed += activity.totalSeconds;
            totalActiveSeconds += activity.activeSeconds;
          }
        });

        const engagementScore = Math.round((totalActiveSeconds * 100) / totalSecondsElapsed) || 0;

        const updatedMember = { member: { ...member } };
        updatedMember.member.totalSecondsElapsed = totalSecondsElapsed;
        updatedMember.member.totalActiveSeconds = totalActiveSeconds;
        updatedMember.member.engagementScore = engagementScore;

        userIds.add(userId);
        mergedTeammates.push(updatedMember);
      }
    });

    setTeams(updatedTeams);
    setMembers(mergedTeammates);
    setMenuMembers(mergedTeammates);
    setSelectedMembers(mergedTeammates);
  };

  const getAlerts = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.dashboardAlerts(activeWorkspace.id),
    });

    if (response.success) {
      const { alerts } = response.data;
      const { categorizedAlerts, alertsByMember } = alerts.reduce(
        (acc, alert) => {
          if (alert.member.isTracking) {
            const { category } = alert;
            const memberId = alert.member.id;
            const userFullName = `${alert.member.user.firstName} ${alert.member.user.lastName}`;

            // Categorize by category
            if (!acc.categorizedAlerts[category]) {
              acc.categorizedAlerts[category] = [];
            }
            acc.categorizedAlerts[category].push({ ...alert, userFullName });

            // Categorize by memberID
            if (!acc.alertsByMember[memberId]) {
              acc.alertsByMember[memberId] = [];
            }
            acc.alertsByMember[memberId].push({ ...alert, userFullName });
          } else {
            // console.log(`Member with ID ${alert.member.id} is not tracking`, alert.member);
          }

          return acc;
        },
        { categorizedAlerts: {}, alertsByMember: {} },
      );

      setHrBotAlerts(categorizedAlerts.HR || []);
      setItBotAlerts(categorizedAlerts.IT || []);
      setInfosecBotAlerts(categorizedAlerts.Infosec || []);
      setAlertsByMember(alertsByMember);
      setMemberAlerts(getTopMembersByAlerts(alertsByMember));
    }
  };

  useEffect(() => {
    if (selectedMemberId !== 0) {
      const selectedMember = members.find((item) => item.memberId === selectedMemberId);
      setSelectedMembers([selectedMember]);
    } else {
      setSelectedMembers(selectedTeam.teamMates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMemberId]);

  useEffect(() => {
    setSelectedMemberId(0);
    if (selectedTeamId !== 0) {
      const selectedTeam = teams.find((item) => item.id === selectedTeamId);
      setSelectedTeam(selectedTeam);
    } else {
      setSelectedTeam({});
      setSelectedMembers(members);
      setMenuMembers(members);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeamId]);

  useEffect(() => {
    if (Object.keys(selectedTeam)?.length) {
      setSelectedMembers(selectedTeam.teamMates);
      setMenuMembers(selectedTeam.teamMates);
      setSelectedTeamId(selectedTeam.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam]);

  useEffect(() => {
    getMembersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    getAlerts();
  }, []);

  useEffect(() => {
    const totalSecondsElapsed = selectedMembers
      .reduce((sum, item) => sum + Number(item.member.totalSecondsElapsed || 0), 0);
    const totalActiveSeconds = selectedMembers
      .reduce((sum, item) => sum + Number(item.member.totalActiveSeconds || 0), 0);
    setTotalSeconds(totalSecondsElapsed);
    setActiveSeconds(totalActiveSeconds);
    setDailyTotalHours(Math.ceil(totalSecondsElapsed / workDays));
    setDailyQualityHours(Math.ceil(totalActiveSeconds / workDays));
  }, [selectedMembers, workDays]);

  return (
    <>

      <div className="d-flex gap-3 mb-3">
        <BotAlertCard title="HR bot alerts" color="#EE7C5C" alerts={hrBotAlerts} />
        <BotAlertCard title="IT bot alerts" color="#F19E41" alerts={itBotAlerts} />
        <BotAlertCard title="Infosec bot alerts" color="#64BA62" alerts={infosecBotAlerts} />
      </div>
      <ActivityStatus totalSeconds={totalSeconds} activeSeconds={activeSeconds} />
      <div className="d-flex align-c gap-3 mb-3">
        <TeamSelect
          menuItems={teams}
          value={selectedTeamId}
          handleForm={(e) => setSelectedTeamId(e.target.value)}
        />
        <MemberSelect
          menuItems={menuMembers}
          value={selectedMemberId}
          handleForm={(e) => setSelectedMemberId(e.target.value)}
        />
        <DateRangePicker
          value={date}
          onChange={(_date) => { setDate(_date); console.log('ttt', _date); }}
          placement="bottomEnd"
        />
      </div>
      <div className="reports-container">
        <div className="reports-wrapper">
          <h6 className="size-s lh-base color-text">Performance Reports</h6>
          <div className="d-flex gap-2">
            <OverallScoreCard score={Math.round((activeSeconds * 100) / totalSeconds)} />
            <DailyQualityCard
              qualityHours={dailyTotalHours}
              qualitySessions={dailyQualityHours}
              score={Math.ceil((dailyQualityHours / dailyTotalHours) * 100) || 0}
            />
          </div>
          <div className="d-flex">
            <CriticalAlertsCard
              hrBotAlerts={hrBotAlerts.length}
              itBotAlerts={itBotAlerts.length}
              infosecBotAlerts={infosecBotAlerts.length}
              memberAlerts={memberAlerts}
            />
          </div>
          <h6 className="size-s lh-base color-text">Major Shifts in Engagement</h6>
          <div className="d-flex">
            <MajorShiftsCard members={selectedMembers} workDays={workDays} />
          </div>
          <h6 className="size-s lh-base color-text">Engagement Opportunities</h6>
          <div className="d-flex">
            <EngagementCard members={selectedMembers} alertsByMember={alertsByMember} />
          </div>
        </div>
        <Menu
          teams={teams}
          members={menuMembers}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          selectedMember={selectedMemberId}
          setSelectedMember={setSelectedMemberId}
        />
      </div>

    </>
  );
}

export default CardDashboard;
