import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box } from '@mui/material';
import { UnfoldMore } from '@mui/icons-material';
import CustomTable from '../../components/table/CustomTable';
import { convertSecToTimeFormat } from '../../utils/time';

const TABLE_HEADERS = [
  {
    label: 'Manager Name',
    // sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'manager.name', // Nested key
    colSpan: 1,
  },
  {
    label: 'Hours worked',
    // sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'stats.hoursWorked', // Nested key
    colSpan: 1,
  },
  {
    label: 'Idle time',
    // sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'stats.idleTime', // Nested key
    colSpan: 1,
  },
  {
    label: 'Expected Hours',
    // sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'stats.expectedHours', // Nested key
    colSpan: 1,
  },
  {
    label: 'Performance',
    // sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'performance',
    colSpan: 0.5,
  },
  // {
  //   label: 'Action',
  //   colSpan: 0.5,
  // },
];

const CustomTableStyles = {
  container: {
    marginTop: 1,
  },
  header: {
    backgroundColor: 'var(--dark-10)',
    fontSize: '14px',
    '.MuiTableSortLabel-iconDirectionAsc': {
      transform: 'rotate(0deg) !important',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: 'var(--dark-10) !important',
    },
  },
  cell: {
    button: {
      textWrap: 'nowrap',
      color: 'var(--dark-2)',
      borderColor: 'var(--dark-4) !important',
      '&:disabled': {
        color: 'var(--dark-5)',
        borderColor: 'var(--dark-7) !important',
        cursor: 'not-allowed',
        pointerEvents: 'all !important',
      },
    },
  },
};

function resolveNestedValue(obj, path) {
  return path.split('.').reduce((acc, key) => (acc ? acc[key] : undefined), obj);
}

function SubordinatesModal({ closeModal, subordinates, expectedHours }) {
  const expectedHoursInHours = expectedHours / 3600;
  const mapSubordinatesToTableRows = (data) => data.map((subordinate) => ({
    managerName: `${subordinate.user.firstName || ''} ${subordinate.user.lastName || ''}`.trim() || 'N/A',
    hoursWorked: convertSecToTimeFormat(subordinate.dailyActivity.activeSeconds),
    idleTime: convertSecToTimeFormat(
      subordinate.dailyActivity.totalSeconds
        ? subordinate.dailyActivity.totalSeconds - subordinate.dailyActivity.activeSeconds
        : 0,
    ),
    expectedHours: expectedHoursInHours, // Default expected hours; update if dynamic.
    performance: subordinate.dailyActivity.activeSeconds
      ? `${Math.round((subordinate.dailyActivity.activeSeconds / 28800) * 100)}%`
      : '0%',
  }));

  const tableRows = mapSubordinatesToTableRows(subordinates.subordinates);

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c subordinates-modal">
      <Box
        style={{
          width: 800,
          padding: 32,
          background: '#fff',
          borderRadius: 8,
          maxHeight: 768,
          overflowY: 'auto',
        }}
      >
        <div className="modal-header">
          <h5 className="text-500">
            {subordinates.name}
            &apos;s Subordinates
          </h5>
        </div>
        <div className="modal-body p-0">
          <div className="py-2 px-3 d-flex justify-content-between align-items-center">
            <div>
              <h4 className="size-s text-600 lh-base fc-dark mb-1">Team Performance</h4>
              <p className="size-xs text-400 lh-sm fc-dark">
                Review every team’s performance along with their engagement details
              </p>
            </div>
          </div>
          <div>
            <CustomTable
              headers={TABLE_HEADERS}
              items={tableRows}
              styles={CustomTableStyles}
              resolveNestedValue={resolveNestedValue}
            />
          </div>
        </div>
        <br />
      </Box>
    </Modal>
  );
}

SubordinatesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  expectedHours: PropTypes.number.isRequired,
  subordinates: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subordinates: PropTypes.arrayOf(
      PropTypes.shape({
        teamName: PropTypes.string,
        manager: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
        stats: PropTypes.shape({
          hoursWorked: PropTypes.number.isRequired,
          idleTime: PropTypes.number.isRequired,
          expectedHours: PropTypes.number.isRequired,
        }).isRequired,
        performance: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default SubordinatesModal;
